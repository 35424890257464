import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/calendar.svg'
import { useNavigate } from "react-router-dom";
import time from '../../assets/time.svg'
import location from '../../assets/pin.svg'
import foto1 from '../../assets/foto4.png'
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import spinner from '../../assets/spinner.gif'
import toRupiah from '@develoka/angka-rupiah-js';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageReview = () => {

    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [timeList, setTimeList] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)
    

    

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }
    useEffect(() =>{
        window.scrollTo(0, 0)
        setLoading(true)
        setDetail({nama:localStorage.getItem("userName"),prefix:localStorage.getItem('prefix'),phoneNumber:localStorage.getItem('phoneNumber'),namaChef:localStorage.getItem("chefName"), foto:localStorage.getItem("chefFoto"),Type:localStorage.getItem("menu_name"),tanggal:localStorage.getItem("dateShown"), jam:localStorage.getItem("jam"),style:localStorage.getItem('style'),pax:localStorage.getItem('pax'), total:localStorage.getItem('total'),address:localStorage.getItem('address'),totalAfter:localStorage.getItem('totalAfter')})
        localStorage.setItem('nav', 'fnd')
        // const timeLists = [];
        // const startTime = new Date();
        // startTime.setHours(8, 0, 0); // Set start time to 08:00:00
        // const endTime = new Date();
        // endTime.setHours(15, 0, 0); // Set end time to 12:00:00
        //     // Loop from start time to end time
        // for (let time = new Date(startTime); time <= endTime; time.setMinutes(time.getMinutes() + 60)) {
        //     // Format the time to display as HH:mm
        //     const formattedTime = time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12:false});
        //     // Push the formatted time to the list
        //     timeLists.push(<li className='small bold white' key={formattedTime}>{formattedTime}</li>);
        //     setTimeList(timeLists)
        // }
    },[])
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[detail])
    const history = useNavigate()

    const convertToRupiah = (angka) => {
        var rupiah = '';		
        var angkarev = angka.toString().split('').reverse().join('');
        for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
        return rupiah.split('',rupiah.length-1).reverse().join('');
    }

    const handleSeeMyOrder = () => {
        localStorage.setItem('isFromBookingChef',true)
        setstandby(false)
        setTimeout(function() {
            history('/MyBooking')
        }, 500);
    }

    
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="RPtrans">
                <div className='RPkontainer'>
                    <p className='white big bold RPtittle'>Thank You</p>
                    <p className='white semibig RPtittle'>Your booking is Under Review</p>
                    <div className='RPkonten flex'>
                        <div className='RPkontainerkiri'>
                            <p className='tiny white'>The Partner is currently reviewing your request (Approx. Under 2 Hours). We will notify you over email/text to proceed the Payment as soon as the event has been accepted.</p>
                        </div>
                        <div className='RPkontainerkanan'>
                        </div>
                    </div>

                    <div className='RPkonten2 flex'>
                        <div className='RPkontainerkiri'>
                            <div className='RPkontenputih'>
                                <div className='flex' style={{alignItems:""}}>
                                    <div style={{marginRight:"4px"}}>
                                        {/* <p className='bold medium'>Fauzan Pradana</p> */}
                                        <p className='bold medium'>{detail.nama}</p>
                                        <p className='medium' style={{marginTop:"4px"}}>{detail.prefix} {detail.phoneNumber}</p>
                                    </div>
                                    <button className='RPstatus bold small'>In Review</button>
                                </div>
                                <div className='flex RPmargin'>
                                    <div className='RPimage' style={{backgroundImage:`url(${detail.foto})`}}></div>
                                    <div>
                                        <p className='small'>{detail.namaChef}</p>
                                        <p className='small' style={{marginTop:"4px"}}>{detail.Type}</p>
                                    </div>
                                </div>
                                <div className='RPmargin flex' style={{alignItems:"center"}}>
                                    <img alt="icon" className='RPicon' src={calendar}></img>
                                    <p className='small' style={{marginRight:"32px"}}>{detail.tanggal}</p>
                                    <img alt="icon" className='RPicon' src={time}></img>
                                    <p className='small' >{detail.jam}</p>
                                </div>
                                <div className='RPmargin flex' style={{alignItems:"center"}}>
                                    <img alt="icon" className='RPicon' src={location}></img>
                                    <p className='small' >{detail.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className='RPkontainerkanan'>
                            <p className='white bold medium'>Invoice Preview</p>
                            <div className='RPmargin2 flex'>
                                <p className='small white'>{detail.pax} pax</p>
                                {/* <p className='small white' style={{marginLeft:"auto"}}>IDR {detail.total}</p> */}
                                <p className='small white' style={{marginLeft:"auto"}}>{toRupiah(detail.total,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                            </div>
                            {/* <div className='RPline RPmargin3'></div>
                            <div className='RPmargin3 flex'>
                                <p className='small white'>Service Fee</p>
                                <p className='small white' style={{marginLeft:"auto"}}>-</p>
                            </div> */}
                            <div className='RPline RPmargin3'></div>
                            <div className='RPmargin3 flex'>
                                <p className='small white'>Voucher Amount</p>
                                <p className='small white' style={{marginLeft:"auto"}}>{localStorage.getItem('voucherDisc') === "" ? "-" : localStorage.getItem('voucherDisc')+"%"}</p>
                            </div>
                            <div className='RPline RPmargin3'></div>
                            <div className='RPmargin3 flex'>
                                <p className='small bold white'>Total</p>
                                <p className='small bold white' style={{marginLeft:"auto"}}>{toRupiah(detail.totalAfter,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                            </div>
                            {/* <div className='RPmargin3 flex'>
                            <h2 className='small bold white'>Times between 08:00 and 12:00:</h2>
                            <ul>{timeList}</ul>
                            </div> */}

                            <button onClick={() => handleSeeMyOrder()} className='RPbutton small bold RPmargin'>See My Order</button>
                            {/* <button className='RPbutton2 small bold RPmargin4'>Explore More</button> */}
                        </div>
                    </div>
                    
                </div>
                </CSSTransition>
            }
        </div>
    )
  }