/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import { PageLogin } from '../LoginPage/PageLogin'
import { ForgotPasswordOTP } from './ForgotPasswordOTP';
import {baseUrl} from '../../lib/api'
import React from 'react';
import OTPInputGroup from '../OTPPage/otpInputs'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageForgotPassword = () => {
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const inputRef = React.createRef();
    const [showOTPContainer, setShowOTPContainer] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    const [regPassword, setRegPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showSuccessSendEmail, setShowSuccessSendEmail] = useState(false)
    const [val1, setval1] = useState(false)
    const [valReg7, setValReg7] = useState(true)
    const [valReg8, setValReg8] = useState(true)
    const [childValue, setChildValue] = useState('');
    const [childValueId, setChildValueId] = useState('');
    const [childIsValue, setChildIsValue] = useState(false);
    const [valOtp, setValOtp] = useState(true)
    const [valOtpSuccess, setValOtpSuccess] = useState(true)

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    useEffect(() =>{
        window.scrollTo(0, 0)
        localStorage.setItem('nav', 'u')
    },[])


    const [standby, setstandby] = useState(true)
    const [email, setEmail] = useState('')


    const history = useNavigate()



    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                history('/')
           }, 500);
        }
    },[standby])

    const handleSubmit = e => {
        e.preventDefault()
        let isError=false
        var elem = document.getElementById("PFP1");
        if(email === ''){
            elem.className = "PUSform PUSred";
            isError=true
            setval1(true)
        }else{
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
                elem.className = "PUSform PUSred";
                isError=true
                setval1(true)
            }
            else{
                elem.className = "PUSform";
                setval1(false)
            }
        }
        if(isError){
            return
        }else{
            callAPISendEmail(email)
            // setstandby(false)
        }
    }

    const callAPISendEmail = async (userEmail) => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataEmail = new FormData();
        formDataEmail.append("email", userEmail)
        axios({
            method: 'POST',
            // url: baseUrl + 'login',
            url: baseUrl + 'sendOtpPassword',
            data : formDataEmail,
            withCredentials:false
        })
        .then(function (response) {
            console.log("response success")
            console.log(response.data)
            console.log(response.data.status_code)
            console.log(response.data.message)
            if(response.data.status_code == 200){
                console.log("berhasil kirim otp")
                setShowOTPContainer(true)
            } else {
                console.log("gagal kirim otp")
            }
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log("response gagal")
            console.log(error);
        });
    }

    const handleChildValue = (value) => {
        setChildValue((prevInputValues) => ({
            ...prevInputValues,
            [value[0]]: value[1],
        }));
        // console.log("show otp value 1 => "+JSON.stringify(value[0]))
        // console.log("show otp value 1 => "+JSON.stringify(value[1]))
        };

    const handleChildValueId = (value) => {
        setChildValueId(value);
        // console.log("show otp value 2 => "+JSON.stringify(value))
        };

    const handleChildIsValue = (value) => {
        setChildIsValue(value);
        // console.log("show otp value 3 => "+value)
        setValOtp(true)
        };

    const handleShowChildValue = () => {
        console.log("show otp value => "+JSON.stringify(childValue))
        // if(childValue.input1)
        // console.log("show otp value => "+childValue.length)
        // console.log("show otp value => "+childValue.input2)
        if(childValue.input1 !== "" && childValue.input2 !== "" && childValue.input3 !== "" && childValue.input4 !== ""){
            console.log("semua ada datanya")
            callAPIValidateOTP(`${childValue.input1}${childValue.input2}${childValue.input3}${childValue.input4}`)
        } else {
            console.log("ada yg kosong")
            setValOtp(false)
        }
    };

    const callAPIValidateOTP = async (otpValue) => {
        let axios = require('axios');
        // let FormData = require('form-data');
        // const formDataRegister = new FormData();
        axios({
            method: 'POST',
            url: baseUrl + 'validasiOtp/'+otpValue,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status_code == 200){
                console.log("berhasil")
                setValOtpSuccess(true)
                // callAPIActivationUser()
            }else{
                setValOtpSuccess(false)
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }
    
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MBPtrans">
            <div className='PFPkontainer'>
                <div className='PFPkonten'>
                    <p className='big bold white'>Reset your password</p>
                    <p className='white small PFPmargin'>Fill on your e-mail address below and we will send you a random password for login</p>
                    <form onSubmit={handleSubmit}>
                        <input id="PFP1" type='email' autoComplete='off' ref={inputRef} value={email} onInput={e => setEmail(e.target.value)} onChange={e => setEmail(e.target.value)} className='PUSform' placeholder='Enter your email'></input>
                        {
                            val1 && (
                                <p className='tiny red PUSval2'>Please fill your email correctly</p>
                            )
                        }
                        <button type="submit" className='bold small PUSmargin PFPbutton'>Send Me a Password</button>
                    </form>
                    <p className='white small PFPmiddle PFPmargin'>Alredy have account? <span onClick={() => setShowLogin(true)} className='PFPunderline bold'>Login here</span></p>
                    <p className='white small PFPmiddle PFPmargin2'>Don’t have account? <span onClick={() => setShowRegister(true)} className='PFPunderline bold'>Sign up here</span></p>
                </div>
            </div>
            </CSSTransition>
            {
                showRegister && (
                <PageLogin openRegister={true} fungsi={() => setShowRegister(false)}></PageLogin>
                )
            }

            {
                showLogin && (
                <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
                )
            }
            {
                 showOTPContainer && (
                    <ForgotPasswordOTP fungsi={() => setShowOTPContainer(false)}></ForgotPasswordOTP>
                //  <div>
                //     <div onClick={setShowOTPContainer(false)} className='LoginKontainer'></div>
                //     <div className='LoginKonten'>
                //         {
                //         showSuccessSendEmail ? 
                //         <div className='LGafterreg'>
                //             <p className='bold semibig' style={{textAlign:"center"}}>Thank You</p>
                //             <img alt="icon" className='LGemail' src={email}></img>
                //             <p className='normal LGnotifreg'>We have received your registration. Please kindly check your email or Whatsapp to get the OTP.</p>
                //             <p className='normal LGnotifreg2'>Please confirm the OTP below.</p>
                //             <div className='OTPInputWrap'>
                //                 <OTPInputGroup onChildValue={(e) =>handleChildValue(e)} onChildIsValue={handleChildIsValue} onChildValueId={handleChildValueId}/>
                //             </div>
                //             {
                //                 !valOtp && 
                //                     (
                //                         <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Please enter otp correctly</p>
                //                     )
                                
                //             }
                //             {
                //                 !valOtpSuccess && 
                //                     (
                //                         <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Data not found, Please check your OTP properly</p>
                //                     )
                                
                //             }
                //             {
                //                 childIsValue ? 
                //                 <button onClick={handleShowChildValue} className='LGbuttonok small bold'>OK</button>
                //                 :
                //                 <button onClick={handleShowChildValue} className='LGbuttonok small bold' disabled>OK</button>
                //             }
                            
                //         </div>
                //         :
                //         <div className='LGafterreg'>
                //             <p className='bold semibig' style={{textAlign:"center"}}>OTP is available</p>
                //             <img alt="icon" className='LGemail' src={email}></img>
                //             {/* <p className='normal LGnotifreg'>We have received your registration. Please kindly check your email to get the OTP.</p> */}
                //             <p className='normal LGnotifreg2'>Please input your new passwod</p>
                //             <div className='OTPInputWrap'>
                //                 <OTPInputGroup onChildValue={(e) =>handleChildValue(e)} onChildIsValue={handleChildIsValue} onChildValueId={handleChildValueId}/>
                //             </div>
                //             {
                //                 valReg7?
                //                 <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform' placeholder='Password'></input>
                //                 :
                //                 <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform LGerror' placeholder='Password'></input>
                //             }
                            
                //             {
                //                 !valReg7 && (
                //                     <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your password with minimum 8 character!</p>
                //                 )
                //             }

                //             {
                //                 valReg8?
                //                 <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform' placeholder='Confirm Password'></input>
                //                 :
                //                 <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform LGerror' placeholder='Confirm Password'></input>
                //             }

                //             {
                //                 !valReg8 && (
                //                     <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your confirmation password correctly!</p>
                //                 )
                //             }
                //             {
                //                 childIsValue ? 
                //                 <button onClick={handleShowChildValue} className='LGbuttonok small bold'>OK</button>
                //                 :
                //                 <button onClick={handleShowChildValue} className='LGbuttonok small bold' disabled>OK</button>
                //             }
                        
                //     </div>
                //     }
                //     </div>
                // </div>
                )
            }
        </div>
    )
  }