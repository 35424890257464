/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageUserSettings = () => {
    const [showNavPopup, setShowNavPopup] = useState(false)

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    useEffect(() =>{
        window.scrollTo(0, 0)
        localStorage.setItem('nav', 'u')
    },[])


    const [standby, setstandby] = useState(true)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const email = localStorage.getItem('username')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [val1, setval1] = useState(false)
    const [val2, setval2] = useState(false)
    const [val3, setval3] = useState(false)
    const [val4, setval4] = useState(false)
    const [val5, setval5] = useState(false)


    const history = useNavigate()

    const handleSubmit = e => {
        e.preventDefault()
        let isError=false
        var elem = document.getElementById("PUS1");
        var elem2 = document.getElementById("PUS2");
        var elem3 = document.getElementById("PUS3");
        var elem4 = document.getElementById("PUS4");
        var elem5 = document.getElementById("PUS5");
        if(firstName === ''){
            elem.className = "PUSform2 PUSred";
            isError=true
            setval1(true)
        }else{
            elem.className = "PUSform2";
            setval1(false)
        }if(lastName === ""){
            elem2.className = "PUSform PUSred";
            isError=true
            setval2(true)
        }else{
            elem2.className = "PUSform";
            setval2(false)
        }if(phone===''){
            elem3.className = "PUSform PUSred";
            isError=true
            setval3(true)
        }else{
            elem3.className = "PUSform";
            setval3(false)
        }if(oldPassword===''){
            isError=true
            elem4.className="PUSform PUSred"
            setval4(true)
        }else{
            elem4.className="PUSform"
            setval4(false)
        }if(newPassword==='' || newPassword===oldPassword){
            isError=true
            elem5.className="PUSform PUSred"
            setval5(true)
        }else{
            elem5.className="PUSform"
            setval5(false)
        }

        if(isError){
            return
        }else{
            setstandby(false)
        }
    }


    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                history('/')
           }, 500);
        }
    },[standby])

    
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MBPtrans">
            <div className='PUSkontainer'>
                <div className='PUSkonten'>
                    <p className='big bold white'>User Settings</p>
                    <form onSubmit={handleSubmit} className='PUSformwrapper'>
                        <div className='flex PUSflex'>
                            <div style={{flex:"50%"}}>
                                <p className='small white'>First Name</p>
                                <input type='text' id="PUS1" value={firstName} onChange={e => setFirstName(e.target.value)} autoComplete='false' className='PUSform2' placeholder='Enter your first name'></input>
                                {
                                    val1 && (
                                        <p className='tiny red PUSval2'>Please fill your first name</p>
                                    )
                                }
                                
                            </div>
                            <div className='PUSmargin2' style={{flex:"50%"}}>
                                <p className='small white'>Last Name</p>
                                <input type='text' id="PUS2" value={lastName} onChange={e => setLastName(e.target.value)} autoComplete='false' className='PUSform' placeholder='Enter your last name'></input>
                                {
                                    val2 && (
                                        <p className='tiny red PUSval'>Please fill your last name</p>
                                    )
                                }
                            </div>
                        </div>
                        <p className='small white PUSmargin'>Phone</p>
                        <input type='text' id="PUS3" value={phone} onChange={e => setPhone(e.target.value.replace(/\D/g, ''))} autoComplete='false' className='PUSform' placeholder='Enter your phone number'></input>
                        {
                            val3 && (
                                <p className='tiny red PUSval'>Please fill your phone number</p>
                            )
                        }

                        <p className='small white PUSmargin'>Email</p>
                        <input type='text' className='PUSform' disabled value={email}></input>
                        <p className='tiny grey' style={{marginTop:"12px"}}>*Email Changed Disallowed</p>

                        <p className='small white PUSmargin'>Old Password</p>
                        <input type='password' id="PUS4" value={oldPassword} onChange={e => setOldPassword(e.target.value)} autoComplete='false' className='PUSform' placeholder='Enter your old password'></input>
                        {
                            val4 && (
                                <p className='tiny red PUSval'>Please fill your old password correctly</p>
                            )
                        }

                        <p className='small white PUSmargin'>New Password</p>
                        <input type='password' id="PUS5" value={newPassword} onChange={e => setNewPassword(e.target.value)} autoComplete='false' className='PUSform' placeholder='Enter your new password'></input>
                        {
                            val5 && (
                                <p className='tiny red PUSval'>Please fill your new password, your new password cannot be same as your old password</p>
                            )
                        }

                        <button type="submit" className='bold small PUSmargin PUSbutton'>Update Profile</button>
                    </form>
                </div>
            </div>
            </CSSTransition>
        </div>
    )
  }