import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route
} from 'react-router-dom'
import { HomePage } from './components/HomePage/HomePage';
import { KategoriPage } from './components/KategoriPage/KategoriPage';
import { ChefListPage } from './components/ListChefPage/ChefListPage'
import { PageDetailChef } from './components/DetailChefPage/PageDetailChef';
import { PageDetailChefSearch } from './components/DetailChefPageSearch/PageDetailChefSearch';
import { PageDetailMenu } from './components/DetailMenuPage/PageDetailMenu';
import { PageSetSchedule } from './components/SetSchedulePage/pageSetSchedule';
import { PageSetVenue } from './components/SetVenuePage/PageSetVenue';
import { PageSubmitBooking } from './components/SubmitBookingPage/PageSubmitBooking';
import { PageReview } from './components/ReviewPage/PageReview';
import { PageMyBooking } from './components/MyBookingPage/PageMyBooking';
import { PageEvent } from './components/EventPage/PageEvent';
import { PageDetailEvent } from './components/DetailEventPage/PageDetailEvent';
import { PageSetEventSchedule } from './components/SetEventSchedulePage/PageSetEventSchedule';
import { PageSubmitBookingEvent } from './components/SubmitBookingEventPage/PageSubmitBookingEvent';
import { PageUserSettings } from './components/UserSettingsPage/PageUserSettings';
import { PageForgotPassword } from './components/ForgotPasswordPage/PageForgotPassword';
import { PageNotFound } from './components/404Page/PageNotFound';
import { MenuList } from './components/MenuList/MenuList';
import { MenuDetail }from './components/DetailNew/DetailMenu';
import { ChefDetail }from './components/DetailNew/DetailChef';
import { ChefList } from './components/MenuList/ChefList';
import { getLoggedInStatus } from './utils/auth';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';
import Hotjar from './utils/hotjar';
import { TaplakEvents } from './components/Event/TaplakEvents';
import { StartPlanningYourEvent } from './components/StartPlaningYourEvent/StartPlanningYourEvent';
import { EventPortfolio } from './components/EventPortfolio/EventPortfolio';

function App() {
  useEffect(()=>{
    hotjar.initialize(5070309, 6) 
  });
  return (
      <Router>
        <Hotjar/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/menus" element={<MenuList/>}/>
          <Route path="/event" element={<TaplakEvents/>}/>
          <Route path="/event/setEvent" element={<StartPlanningYourEvent/>}/>
          <Route path="/event/portfolio" element={<EventPortfolio/>}></Route>
          <Route path="/menus/detail" element={<MenuDetail/>}/>
          <Route path="/chefs" element={<ChefList/>}/>
          <Route path="/chefs/detail" element={<ChefDetail/>}/>
          <Route path="/category" element={<KategoriPage/>}/>
          <Route path="/category/chefList" element={<ChefListPage/>}/>
          <Route path="/category/chefList/detailChef" element={<PageDetailChef/>}/>
          <Route path="/category/chefList/detailChefSearch" element={<PageDetailChefSearch/>}/>
          <Route path="/category/chefList/detailChef/detailMenu" element={<PageDetailMenu/>} />
          <Route path="/category/chefList/detailChef/detailMenu/setSchedule" element={<PageSetSchedule/>} />
          <Route path="/category/chefList/detailChef/detailMenu/setSchedule/setVenue" element={<PageSetVenue/>}/>
          <Route path="/category/chefList/detailChef/detailMenu/setSchedule/setVenue/submitBooking" element={<PageSubmitBooking/>}/>
          <Route path="/category/chefList/detailChef/detailMenu/setSchedule/setVenue/submitBooking/Review" element={<PageReview/>}/>
          <Route path="/MyBooking" element={<PageMyBooking/>}/>
          <Route path="/popups" element={<PageEvent/>}/>
          <Route path="/forgotPassword" element={<PageForgotPassword/>}/>
          <Route path="/userSettings" element={
            getLoggedInStatus() ? <PageUserSettings/> : <Navigate to="/" />
          }/>
          <Route path="/event/detailEvent" element={<PageDetailEvent/>}/>
          <Route path="/event/detailEvent/setSchedule" element={<PageSetEventSchedule/>}/>
          <Route path="/event/detailEvent/setSchedule/submitBooking" element={<PageSubmitBookingEvent/>}/>
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </Router>
  );
}

export default App;
